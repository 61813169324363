/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { ThemeProvider } from './src/context/themeContext';
import vhCheck from 'vh-check';

export const wrapRootElement = ({ element }) => {
  vhCheck();

  return <ThemeProvider>{element}</ThemeProvider>;
};
